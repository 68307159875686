@use '@angular/material' as mat;
@import "@angular/material/theming";
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
// @include mat.legacy-typography-hierarchy(mat.define-typography-config());
@include mat.all-component-typographies();
@include mat.core();
//@include mat.legacy-core();

@import "./reset";
@import "./colors";
@import "./water.theme";
@import "./material_updates";

@mixin app-theme($theme) {
  $primary: map-get($theme, primary);

  a.active {
    background-color: mat.get-color-from-palette($primary, darker);
  }

  a.disableActive {
    background-color: transparent;
  }

  .mat-mdc-list-item.active {
    background-color: mat.get-color-from-palette($primary) !important;
    color: mat.get-contrast-color-from-palette($primary, 500) !important;
  }

  .sidenav-container mat-sidenav {
    background: mat.get-color-from-palette($primary);
    color: mat.get-contrast-color-from-palette($primary, 500);
  }

  .mat-drawer.mat-drawer-push {
    background-color: mat.get-color-from-palette($primary);
    color: mat.get-contrast-color-from-palette($primary, 500);
  }
}

//@include mat.all-legacy-component-themes($water-theme);
@include mat.all-component-themes($water-theme);
@include app-theme($water-theme);
//@include mat.legacy-button-theme($water-theme);
//@include mat.legacy-radio-theme($water-theme);

.flex-spacer {
  flex: 1;
}

.mat-mdc-list-base {
  padding-top: 0px !important;
}

.hintMsg {
  position: absolute;
  top: 42%;
  left: 42%;
}

.errorMsg {
  position: absolute;
  top: 42%;
  left: 42%;
  color: #fa3612;
}


