@use '@angular/material' as mat;
@import "./_colors";

@include mat.core();
@include mat.all-component-typographies();
$default-typography: mat.define-typography-config();
@include mat.typography-hierarchy($default-typography);

.mdc-dialog__title {
  padding: 0 0 9px;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: normal;
}

.mat-mdc-card {
  padding: 16px;
}

.mat-mdc-button, .mdc-button {
  letter-spacing: normal;
}

.mdc-tab {
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-weight: 400;
}

.mat-mdc-form-field {
  font-size: 14px;
}

.mat-mdc-floating-label {
  font-size: 14px;
}

.mat-mdc-option span {
  font-size: 16px
}

.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
  flex-grow: 0;
  height: 48px;
  padding: 0 24px;
  cursor: pointer;
  box-sizing: border-box;
  opacity: .6;
  min-width: 160px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  vertical-align: top;
  text-decoration: none;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs {
  .mat-mdc-tab-link.mdc-tab-indicator--active {
    opacity: 1;
  }
}

.mat-mdc-option.mdc-list-item, .mat-mdc-select-value-text {
  font-size: 14px;
}

.mat-expansion-panel {
  background: inherit;
  margin-top: 0.5em;
}

/* .mat-mdc-form-field .mat-mdc-text-field-wrapper:not(.mdc-text-field--disabled) {
   background-color: $form-field-background;
} */

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mdc-list-group__subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  margin: 0 0 5px;
}

.mat-mdc-form-field-flex {
  padding-right: 0.75em;
}
